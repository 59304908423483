<template>
    <projects-module-component/>
</template>

<script>
    import ProjectsModuleComponent from "@/components/admin/ProjectsModuleComponent";
    
    export default {
        name: "ProjectsModule",
        title: "Proyectos | Private",
        components: {ProjectsModuleComponent}
    }
</script>

<style scoped>

</style>