<template>
    <v-container fill-height fluid text-xs-center>
        <v-card class="fill-height container--fluid" width="100vw" flat outlined>
            <v-data-table
                :headers="headers"
                :items="projects"
                :items-per-page="10"
                :search="searchProject"
                :loading="loadingData"
                :expanded="expanded"
                single-expand
                show-expand
                item-key="id"
                sort-by="id"
                disable-sort
                no-data-text="No hay datos para mostrar..."
                loading-text="Cargando los datos, por favor espere."
                class="elevation-1"
                :footer-props="{
                        showFirstLastPage: true,
                        disableItemsPerPage: false,
                        itemsPerPageAllText: 'Todas',
                        itemsPerPageText: 'Filas por página:'
                    }">
                <template v-slot:top>
                    <v-toolbar flat class="align-center">
                        <v-toolbar-title :class="$vuetify.breakpoint.smAndDown ? 'text-subtitle-1 text-md-h5' : ''">
                            <span>Catálogo de Proyectos</span>
                        </v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-responsive max-width="300" height="45" class="hidden-sm-and-down">
                            <v-text-field
                                id="findtext-project"
                                label="Buscar..."
                                name="findproject"
                                outlined
                                dense
                                single-line
                                clearable
                                background-color="white"
                                hide-details
                                v-model="searchProject"
                                class="white--text mt-0 pt-0 mr-0"
                                prepend-inner-icon="mdi-magnify"
                            ></v-text-field>
                        </v-responsive>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon
                                       depressed
                                       elevation="0"
                                       color="blue darken-1"
                                       class="mb-2"
                                       v-bind="attrs"
                                       v-on="on"
                                       @click="showArchivedProjects">
                                    <v-icon>mdi-archive</v-icon>
                                </v-btn>
                            </template>
                            <span>Mostrar/Ocultar Proyectos Archivados</span>
                        </v-tooltip>

                        <v-dialog
                            :fullscreen="$vuetify.breakpoint.smAndDown"
                            v-model="dialog" max-width="1000px" persistent>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn :small="$vuetify.breakpoint.smAndDown"
                                       color="blue darken-1"
                                       dark
                                       depressed
                                       elevation="0"
                                       class="mb-2"
                                       v-bind="attrs"
                                       v-on="on"
                                       @click="newItem">
                                    Adicionar
                                </v-btn>
                            </template>
                            <v-card>
                                <v-form
                                    ref="form"
                                    v-model="valid"
                                    lazy-validation>
                                    <v-card-title class="text-h6 primary">
                                        <span class="white--text headline">{{ formTitle }}</span>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-container class="mt-0 pt-0">
                                            <v-row dense class="mt-0 pt-0">
                                                <v-col cols="12" md="6">
                                                    <v-container>
                                                        <v-row dense>
                                                            <v-col cols="12">
                                                                <v-text-field
                                                                    dense
                                                                    v-model="editedItem.name"
                                                                    label="Nombre"
                                                                    :rules="textRules"
                                                                    required>
                                                                </v-text-field>
                                                            </v-col>
                                                            <v-col cols="12">
                                                                <v-combobox
                                                                    dense
                                                                    v-model="editedItem.location"
                                                                    :items="places"
                                                                    :loading="loadingPlaces"
                                                                    :search-input.sync="searchQuery"
                                                                    :rules="[v => !!v || 'La dirección de ubicación obligatoria']"
                                                                    no-data-text="No hay resultados de búsqueda"
                                                                    item-text="place_name"
                                                                    no-filter
                                                                    clearable
                                                                    required
                                                                    label="Ubicación"
                                                                    v-on="$listeners"
                                                                    v-on:change="getSelectedItem"
                                                                    @click:clear="onClear">
                                                                </v-combobox>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-container>
                                                        <v-row dense>
                                                            <v-col cols="12">
                                                                <v-textarea
                                                                    dense
                                                                    v-model="editedItem.description"
                                                                    label="Descripción"
                                                                    :rules="textRules"
                                                                    no-resize
                                                                    rows="3"
                                                                    counter
                                                                    required>
                                                                </v-textarea>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-col>
                                            </v-row>
                                            <v-row dense>
                                                <v-col cols="12" md="6">
                                                    <v-menu
                                                        v-model="menu_date1"
                                                        :close-on-content-click="false"
                                                        transition="scale-transition"
                                                        offset-y
                                                        max-width="290">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                dense
                                                                :value="computedStartDateFormatted"
                                                                clearable
                                                                label="Fecha de inicio"
                                                                readonly
                                                                prepend-icon="mdi-calendar"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                @click:clear="editedItem.start_date = null">
                                                            </v-text-field>
                                                            <!--@blur="editedItem.finish_date = parseDate(editedItem.finish_date)"-->
                                                        </template>
                                                        <v-date-picker
                                                            v-model="editedItem.start_date"
                                                            no-title
                                                            color="orange"
                                                            locale="es-ES"
                                                            @change="menu_date1 = false"
                                                        ></v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-menu
                                                        v-model="menu_date2"
                                                        :close-on-content-click="false"
                                                        transition="scale-transition"
                                                        offset-y
                                                        max-width="290">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                dense
                                                                :value="computedFinishDateFormatted"
                                                                clearable
                                                                label="Fecha estimada de terminación"
                                                                readonly
                                                                prepend-icon="mdi-calendar"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                @click:clear="editedItem.finish_date = null">
                                                            </v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="editedItem.finish_date"
                                                            no-title
                                                            color="orange"
                                                            locale="es-ES"
                                                            @change="menu_date2 = false"
                                                        ></v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-text-field-money
                                                        v-model.number="editedItem.investment"
                                                        label="Inversión"
                                                        v-bind:properties="{
                                                            dense: true,
                                                            prefix: '$',
                                                            readonly: false,
                                                            disabled: false,
                                                            outlined: false,
                                                            clearable: false,
                                                            placeholder: '',
                                                          }"
                                                        v-bind:options="{
                                                        locale: 'es-MX',
                                                        length: 11,
                                                        precision: 2,
                                                        empty: null,
                                                      }"
                                                    />
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-text-field-percent
                                                        v-model.number="editedItem.project_participation"
                                                        label="Participación del proyecto"
                                                        v-bind:properties="{
                                                        suffix: '%',
                                                        dense: true,
                                                        readonly: false,
                                                        disabled: false,
                                                        outlined: false,
                                                        clearable: false,
                                                        placeholder: '',
                                                      }"
                                                        v-bind:options="{
                                                        
                                                        length: 3,
                                                        precision: 0,
                                                        empty: null,
                                                      }"
                                                    />
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-text-field-money
                                                        v-model.number="editedItem.expected_return_investment"
                                                        label="Retorno de Inversión Esperado"
                                                        v-bind:properties="{
                                                            dense: true,
                                                            prefix: '$',
                                                            readonly: false,
                                                            disabled: false,
                                                            outlined: false,
                                                            clearable: false,
                                                            placeholder: '',
                                                          }"
                                                        v-bind:options="{
                                                        locale: 'es-MX',
                                                        length: 11,
                                                        precision: 2,
                                                        empty: null,
                                                      }"
                                                    />
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-text-field
                                                        dense
                                                        v-model="editedItem.youtube_video"
                                                        label="Canal de Youtube"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-select
                                                        dense
                                                        v-model="editedItem.status"
                                                        :items="statusList"
                                                        item-text="text"
                                                        item-value="value"
                                                        label="Estado del proyecto"
                                                        :rules="[v => !!v || 'El estado del proyecto es obligatorio']"
                                                        required
                                                    ></v-select>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-checkbox
                                                        dense
                                                        v-model="editedItem.active"
                                                        label="¿Activo?"
                                                        :rules="[v => !!v || 'Debes de marcar Activo para continuar']"
                                                        required
                                                    ></v-checkbox>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="primary" text @click="close">
                                            Cancelar
                                        </v-btn>
                                        <v-btn :disabled="!valid" color="primary" text @click="save">
                                            Guardar
                                        </v-btn>
                                    </v-card-actions>
                                </v-form>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDelete" width="auto">
                            <v-card>
                                <v-card-title class="text-body-1 headline">
                                    ¿Estás seguro que desea eliminar este Proyecto?
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="closeDelete">
                                        Cancelar
                                    </v-btn>
                                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                                        Proceder
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogArchive" max-width="500px">
                            <v-card>
                                <v-card-title class="text-h6 headline">
                                    ¿Estás seguro que desea Archivar este Proyecto?
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="closeArchive">
                                        Cancelar
                                    </v-btn>
                                    <v-btn color="blue darken-1" text @click="archiveItemConfirm">
                                        Proceder
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDeletePicture" max-width="500px">
                            <v-card>
                                <v-card-title class="text-h6 headline">
                                    ¿Desea eliminar esta Imagen de la Galería?
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="closeDeletePicture">
                                        Cancelar
                                    </v-btn>
                                    <v-btn color="blue darken-1" text @click="deleteItemConfirmPicture">
                                        Proceder
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDeleteDocument" max-width="500px">
                            <v-card>
                                <v-card-title class="text-h6 headline">
                                    ¿Desea eliminar este Documento de la Lista?
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="closeDeleteDocument">
                                        Cancelar
                                    </v-btn>
                                    <v-btn color="blue darken-1" text @click="deleteItemConfirmDocument">
                                        Proceder
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            v-model="dialogDeleteError"
                            persistent
                            max-width="600">
                            <v-card>
                                <v-card-title class="text-h6 primary lighten-2">
                                    <span class="headline">Error Eliminando Proyecto</span>
                                </v-card-title>
                                <v-card-text>
                                    <div class="text-h6 pa-8" v-html="messageError"></div>
                                </v-card-text>
                                <v-card-actions class="justify-center">
                                    <v-btn color="blue darken-1" text @click="archiveItemConfirm">
                                        Si(Archivar)
                                    </v-btn>
                                    <v-btn color="blue darken-1" text @click.stop="closeDialogDeleteError">
                                        No(Cancelar)
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            v-model="dialogError"
                            persistent
                            max-width="500">
                            <v-card>
                                <v-card-title class="text-h6 primary lighten-2">
                                    <span class="headline">Error de Validación</span>
                                </v-card-title>
                                <v-card-text>
                                    <div class="text-h6 pa-12">{{ messageError }}</div>
                                </v-card-text>
                                <v-card-actions class="justify-center">
                                    <v-btn text @click.stop="closeDialogError">
                                        Cerrar
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            :fullscreen="$vuetify.breakpoint.smAndDown"
                            v-model="dialogDocumentsPDF"
                            persistent hide-overlay
                            width="600">
                            <v-card>
                                <v-toolbar dark color="primary">
                                    <v-btn
                                        class="hidden-sm-and-down"
                                        icon
                                        dark
                                        @click="dialogDocumentsPDF = false">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                    <v-toolbar-title>Administrador de Documentos PDF</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-toolbar-items>
                                        <v-btn dark
                                               text
                                               @click="dialogDocumentsPDF = false">
                                            Cerrar
                                        </v-btn>
                                    </v-toolbar-items>
                                </v-toolbar>
                                <v-container fill-height bottom-gradient fluid class="ma-0">
                                    <v-row>
                                        <v-col>
                                            <div class="fill-height">
                                                <v-list dense subheader>
                                                    <v-subheader>Lista de Archivos</v-subheader>
                                                    <v-divider/>
                                                    <v-list-item
                                                        v-for="document in editedItem.projects_documents"
                                                        :key="document.id">
                                                        <v-list-item-icon>
                                                            <v-icon color="primary">
                                                                mdi-file-document
                                                            </v-icon>
                                                        </v-list-item-icon>
                                                        <v-list-item-content>
                                                            <v-list-item-title
                                                                v-text="document.name"></v-list-item-title>
                                                        </v-list-item-content>
                                                        <v-list-item-icon>
                                                            <v-tooltip bottom v-if="document.url != null">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn
                                                                        dark
                                                                        icon
                                                                        color="primary"
                                                                        class="ma-0 text--darken-1"
                                                                        elevation="0"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        v-on:click="deleteDocument(document)">
                                                                        <v-icon>mdi-delete</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Eliminar documento</span>
                                                            </v-tooltip>
                                                        </v-list-item-icon>
                                                    </v-list-item>
                                                </v-list>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <div class="container mt-0 pt-0">
                                    <v-file-input v-model="selectedPDFFiles"
                                                  small-chips
                                                  accept=".pdf"
                                                  show-size
                                                  multiple
                                                  clearable
                                                  label="Agregar ficheros"
                                                  @change="inputPDFChanged()">
                                        <template v-slot:selection="{ index, text }">
                                            <v-chip small text-color="white" color="#295671" close
                                                    @click:close="removeChipPDF(index)">
                                                {{ text }}
                                            </v-chip>
                                        </template>
                                    </v-file-input>
                                    <div v-if="imageFiles">
                                        <h5>Archivos seleccionados</h5>
                                        <v-chip v-for="(f, i) in pdfFiles" :key="i" class="mr-1">
                                            {{ f.name }}
                                        </v-chip>
                                        <div class="py-3">
                                            <v-btn color="primary" @click="uploadDocuments()" :disabled="selectedPDFFiles.length === 0">
                                                Actualizar Lista
                                            </v-btn>
                                        </div>
                                    </div>
                                </div>
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            :fullscreen="$vuetify.breakpoint.smAndDown"
                            v-model="dialogGalleryPhotos"
                            persistent hide-overlay
                            width="90%">
                            <v-card>
                                <v-toolbar dark color="primary">
                                    <v-btn
                                        class="hidden-sm-and-down"
                                        icon
                                        dark
                                        @click="dialogGalleryPhotos = false">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                    <v-toolbar-title>Administrador de Imágenes</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-toolbar-items>
                                        <v-btn dark
                                               text
                                               @click="dialogGalleryPhotos = false">
                                            Cerrar
                                        </v-btn>
                                    </v-toolbar-items>
                                </v-toolbar>
                                <v-container fill-height bottom-gradient fluid class="ma-0">
                                    <v-row>
                                        <v-col>
                                            <div class="fill-height">
                                                <v-slide-group
                                                    center-active
                                                    class="pa-0"
                                                    next-icon="mdi-arrow-right-drop-circle-outline"
                                                    prev-icon="mdi-arrow-left-drop-circle-outline"
                                                    show-arrows>
                                                    <v-slide-item
                                                        v-for="itemImage in editedItem.pictures"
                                                        :key="itemImage.id"
                                                        v-slot="{ active, toggle }">
                                                        <v-card
                                                            :color="active ? undefined : 'white'"
                                                            class="ma-4 rounded-lg"
                                                            height="auto"
                                                            width="250"
                                                            elevation="10"
                                                            @click="toggle">
                                                            <v-img
                                                                :src="itemImage.url != null ? itemImage.url : require('@/assets/images/no-image.jpg')"
                                                                :lazy-src="require('@/assets/images/no-image.jpg')"
                                                                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                                                aspect-ratio="1"
                                                                height="250"
                                                                class="white--text align-end">
                                                                <v-container fluid
                                                                             class="container--fluid fill-height px-1 py-1">
                                                                    <v-row dense>
                                                                        <v-col cols="6">
                                                                            <v-icon color="yellow" small>
                                                                                mdi-tag
                                                                            </v-icon>
                                                                            <span
                                                                                class="text-subtitle-1 white--text mr-2">
                                                                                {{
                                                                                    computeTagImage(itemImage.pivot.picture_category)
                                                                                }}
                                                                            </span>
                                                                        </v-col>
                                                                        <v-col cols="6" class="text-end">
                                                                            <v-tooltip bottom
                                                                                       v-if="itemImage.url != null">
                                                                                <template
                                                                                    v-slot:activator="{ on, attrs }">
                                                                                    <v-btn
                                                                                        dark
                                                                                        icon
                                                                                        color="yellow"
                                                                                        class="ma-0 text--darken-1"
                                                                                        elevation="0"
                                                                                        v-bind="attrs"
                                                                                        v-on="on"
                                                                                        v-on:click="markAsFavorite(itemImage)">
                                                                                        <v-icon>
                                                                                            {{
                                                                                                itemImage.pivot.favorite === 1 ? 'mdi-star' : 'mdi-star-outline' }}
                                                                                        </v-icon>
                                                                                    </v-btn>
                                                                                </template>
                                                                                <span>Marcar como favorita</span>
                                                                            </v-tooltip>
                                                                            <v-menu
                                                                                top
                                                                                offset-y
                                                                                left>
                                                                                <template
                                                                                    v-slot:activator="{ on: menu, attrs }">
                                                                                    <v-tooltip bottom>
                                                                                        <template
                                                                                            v-slot:activator="{ on: tooltip }">
                                                                                            <v-btn
                                                                                                icon
                                                                                                color="yellow"
                                                                                                v-bind="attrs"
                                                                                                v-on="{ ...tooltip, ...menu }">
                                                                                                <v-icon>
                                                                                                    mdi-dots-vertical
                                                                                                </v-icon>
                                                                                            </v-btn>
                                                                                        </template>
                                                                                        <span>Etiquetar la Imagen</span>
                                                                                    </v-tooltip>
                                                                                </template>
                                                                                <v-list>
                                                                                    <v-list-item
                                                                                        v-for="(item, i) in tagItems"
                                                                                        :key="i"
                                                                                        @click="tagImage(itemImage.id, item.tag)">
                                                                                        <v-list-item-content>
                                                                                            <v-list-item-title>
                                                                                                {{ item.title }}
                                                                                            </v-list-item-title>
                                                                                        </v-list-item-content>
                                                                                    </v-list-item>
                                                                                </v-list>
                                                                            </v-menu>
                                                                            <v-tooltip bottom
                                                                                       v-if="itemImage.url != null">
                                                                                <template
                                                                                    v-slot:activator="{ on, attrs }">
                                                                                    <v-btn
                                                                                        dark
                                                                                        icon
                                                                                        color="yellow"
                                                                                        class="ma-0 text--darken-1"
                                                                                        elevation="0"
                                                                                        v-bind="attrs"
                                                                                        v-on="on"
                                                                                        v-on:click="deletePicture(itemImage)">
                                                                                        <v-icon>mdi-delete</v-icon>
                                                                                    </v-btn>
                                                                                </template>
                                                                                <span>Eliminar imagen</span>
                                                                            </v-tooltip>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-container>
                                                                <template v-slot:placeholder>
                                                                    <v-row
                                                                        class="fill-height ma-0"
                                                                        align="center"
                                                                        justify="center">
                                                                        <v-progress-circular
                                                                            indeterminate
                                                                            color="grey lighten-5"
                                                                        ></v-progress-circular>
                                                                    </v-row>
                                                                </template>
                                                            </v-img>
                                                        </v-card>
                                                    </v-slide-item>
                                                </v-slide-group>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <div class="container mt-0 pt-0">
                                    <v-file-input v-model="selectedFiles"
                                                  small-chips
                                                  accept="image/png, image/jpeg, image/bmp"
                                                  show-size
                                                  multiple
                                                  clearable
                                                  label="Agregar ficheros"
                                                  @change="inputImageChanged">
                                        <template v-slot:selection="{ index, text }">
                                            <v-chip small text-color="white" color="#295671" close
                                                    @click:close="removeImageChip(index)">
                                                {{ text }}
                                            </v-chip>
                                        </template>
                                    </v-file-input>
                                    <div v-if="imageFiles">
                                        <h5>Archivos seleccionados</h5>
                                        <v-chip v-for="(f, i) in imageFiles" :key="i" class="mr-1">
                                            {{ f.name }}
                                        </v-chip>
                                        <div class="py-3">
                                            <v-btn color="primary" @click="uploadPictures()" :disabled="selectedFiles.length === 0">
                                                Actualizar Galería
                                            </v-btn>
                                        </div>
                                    </div>
                                </div>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.active="{ item }">
                    <v-simple-checkbox
                        v-model="item.active"
                        disabled
                    ></v-simple-checkbox>
                </template>
                <template v-slot:item.start_date="{ item }">
                    <span>{{ formatDate(item.start_date) }}</span>
                </template>
                <template v-slot:item.finish_date="{ item }">
                    <span>{{ formatDate(item.finish_date) }}</span>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-tooltip top v-if="!item.archived">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="mr-2"
                                @click="editItem(item)">
                                mdi-pencil
                            </v-icon>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip top v-if="!item.archived">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="mr-2"
                                @click="loadGalleryImages(item)">
                                mdi-camera
                            </v-icon>
                        </template>
                        <span>Administrar Imágenes</span>
                    </v-tooltip>
                    <v-tooltip top v-if="!item.archived">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="mr-2"
                                @click="loadListDocuments(item)">
                                mdi-file-document
                            </v-icon>
                        </template>
                        <span>Administrar Documentos PDF</span>
                    </v-tooltip>
                    <v-tooltip top v-if="!item.archived">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="mr-2"
                                @click="deleteItem(item)">
                                mdi-delete
                            </v-icon>
                        </template>
                        <span>Eliminar</span>
                    </v-tooltip>
                    <v-tooltip top v-if="item.archived">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                color="black"
                                small
                                class="mr-2"
                                @click="undoArchiveItem(item)">
                                mdi-archive
                            </v-icon>
                        </template>
                        <span>Desarchivar proyecto</span>
                    </v-tooltip>
                </template>
                <template v-slot:no-data>
                    <span class="text-h5">No hay registros en el listado</span>
                    <br/>
                    <v-btn color="primary" @click="getProjects">
                        Recargar
                    </v-btn>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                        <v-container fluid>
                            <v-row justify="start" class="ma-1">
                                <h3>{{ item.name }}</h3>
                            </v-row>
                            <v-row class="ma-1">
                                <v-col cols="12" md="8">
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="12" md="4">
                                            Descripción:
                                        </v-col>
                                        <v-col>
                                            {{ item.description }}
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="12" md="4">
                                            Ubicación:
                                        </v-col>
                                        <v-col>
                                            {{ item.location }}
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="12" md="4">
                                            Fecha de inicio:
                                        </v-col>
                                        <v-col>
                                            {{ formatDate(item.start_date) }}
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="12" md="4">
                                            Fecha estimada de terminación:
                                        </v-col>
                                        <v-col>
                                            {{ formatDate(item.finish_date) }}
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="12" md="4">
                                            Inversión:
                                        </v-col>
                                        <v-col>
                                            $ {{ item.investment }}
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="12" md="4">
                                            Participación del proyecto:
                                        </v-col>
                                        <v-col>
                                            {{ item.project_participation }} %
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="12" md="4">
                                            Retorno de inversión esperado:
                                        </v-col>
                                        <v-col>
                                            $ {{ item.expected_return_investment }}
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="12" md="4">
                                            Canal de Youtube:
                                        </v-col>
                                        <v-col>
                                            <span v-if="item.youtube_video !== null">
                                                {{ item.youtube_video }}
                                            </span>
                                            <span v-else>
                                                (ninguno)
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="12" md="4">
                                            Estado del proyecto:
                                        </v-col>
                                        <v-col>
                                            <span v-if="item.status === 'started'">
                                                Iniciado
                                            </span>
                                            <span v-else-if="item.status === 'execution'">
                                                En ejecución
                                            </span>
                                            <span v-else>
                                                Terminado
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-row justify="center">
                                        <v-img
                                            :src="item.pictures[0] != null ? item.pictures[0].url : require('@/assets/images/no-image.jpg')"
                                            :lazy-src="require('@/assets/images/no-image.jpg')"
                                            height="100%"
                                            width="100%"
                                            aspect-ratio="1"
                                            class="white--text align-center justify-center elevation-2">
                                        </v-img>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </td>
                </template>
            </v-data-table>
            <v-snackbar
                v-model="snackBar"
                :timeout="2000">
                {{ snackText }}
                <template v-slot:action="{ attrs }">
                    <v-btn v-bind="attrs"
                           icon
                           @click="snackBar = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-snackbar>
        </v-card>
    </v-container>
</template>

<script>
    import projectsService from '@/providers/ProjectsService';
    import moment from 'moment';

    export default {
        name: "ProjectsModuleComponent",
        props: {
            title: String,
            breadcrumbs: Array,
            icon: String,
        },
        data: () => ({
            // MapBox Location
            placeNameLimit: 60,
            selectedPlace: {
                place_name: '',
                center: []
            },
            placesList: [],
            loadingPlaces: false,
            searchQuery: null,
            mapBoxApiKey: process.env.VUE_APP_MAPBOX_API_KEY,
            menu_date1: false,
            menu_date2: false,
            focus: false,
            snackBar: false,
            snackText: '',
            valid: true,
            archived: false,
            searchProject: "",
            loadingData: false,
            dialog: false,
            dialogArchive: false,
            dialogDelete: false,
            dialogError: false,
            dialogDeleteError: false,
            // Images files
            dialogDeletePicture: false,
            dialogGalleryPhotos: false,
            currentPicture: undefined,
            selectedFiles: [],
            imageFiles: [],
            // PDF files
            dialogDocumentsPDF: false,
            dialogDeleteDocument: false,
            currentDocument: undefined,
            selectedPDFFiles: [],
            pdfFiles: [],
            messageError: '',
            statusList: [
                // {value: 'waiting', text: 'En espera'},
                {value: 'started', text: 'Iniciado'},
                // {value: 'pending', text: 'Pendiente'},
                {value: 'execution', text: 'En ejecución'},
                // {value: 'aborted', text: 'Abortado'},
                // {value: 'belated', text: 'Retrasado'},
                {value: 'finished', text: 'Terminado'}
            ],
            tagItems: [
                {title: 'Proyecto', tag: 'project'},
                {title: 'Arquitectura', tag: 'architectonic'},
                {title: 'Disposición', tag: 'disposition'}
            ],
            projects: [],
            expanded: [],
            headers: [
                {text: '', value: 'data-table-expand', width: 5},
                {text: "Id", value: "id", align: " d-none"}, // para ocultar la columna Id.
                {
                    text: "Nombre del Proyecto",
                    align: "start",
                    sortable: false,
                    value: "name"
                },
                {text: "Ubicación", value: "location"},
                {text: "Fecha de Inicio", value: "start_date"},
                {text: "Fecha Estimada Terminación", value: "finish_date"},
                // {text: 'Activo', value: 'active', align: 'center', width: 100},
                {text: "Acciones", value: "actions", width: 160, align: "center", sortable: false}
            ],
            textRules: [
                v => !!v || 'Los datos de este campo son obligatorio'
            ],
            emailRules: [
                v => !!v || "El Correo Electrónico es obligatorio",
                v => /.+@.+\..+/.test(v) || "El Correo Electrónico debe ser válido."
            ],
            imageRules: [
                value =>
                    !value ||
                    value.size < 2000000 ||
                    "El tamaño de la imagen no debe de sobrepasar los 2 MB"
            ],
            archiveItemId: undefined,
            editedIndex: -1,
            editedItem: {
                id: 0,
                name: "",
                description: "",
                location: "",
                map_lat: 0.0,
                map_lng: 0.0,
                start_date: new Date(Date.now()).toISOString(),
                finish_date: new Date(Date.now()).toISOString(),
                investment: 0,
                // investment_amount: 0,
                project_participation: 0,
                // utilities: 0,
                // return_percentage: 0,
                expected_return_investment: 0,
                youtube_video: "",
                active: true,
                status: 'waiting',
                pictures: [],
                projects_documents: []
            },
            defaultItem: {
                id: 0,
                name: "",
                description: "",
                location: "",
                map_lat: 0.0,
                map_lng: 0.0,
                start_date: new Date(Date.now()).toISOString(),
                finish_date: new Date(Date.now()).toISOString(),
                investment: 0,
                // investment_amount: 0,
                project_participation: 0,
                // utilities: 0,
                // return_percentage: 0,
                expected_return_investment: 0,
                youtube_video: "",
                active: true,
                status: 'waiting',
                pictures: [],
                projects_documents: []
            }
        }),
        filter: {
            formatTheDate(value) {
                if (value) {
                    return moment(String(value)).format('DD/MM/yyyy');
                }
            },
        },
        computed: {
            formTitle() {
                return this.editedIndex === -1 ? "Nuevo Proyecto" : "Editar Proyecto";
            },
            places() {
                let listPlaces = [];
                if (this.placesList) {
                    listPlaces = this.placesList.map(entry => {
                        const PlaceName = entry.place_name.length > this.placeNameLimit
                            ? entry.place_name.slice(0, this.placeNameLimit) + '...'
                            : entry.place_name;
                        console.log('PlaceName: ', PlaceName);
                        return Object.assign({}, entry);
                    });
                }
                return listPlaces;
            },
            computedStartDateFormatted() {
                return this.editedItem.start_date ? this.formatDate(this.editedItem.start_date) : '';
            },
            computedFinishDateFormatted() {
                return this.editedItem.finish_date ? this.formatDate(this.editedItem.finish_date) : '';
            }
        },
        watch: {
            dialog(val) {
                val || this.close();
            },
            dialogDelete(val) {
                val || this.closeDelete();
            },
            dialogDeletePicture(val) {
                val || this.closeDeletePicture();
            },
            searchQuery(val) {
                if (this.queryReady(val)) {
                    this.searchPlaces(val);
                }
            }
        },
        methods: {
            formatDate(value) {
                if (!value) return null;
                let theDate = value.toString().substr(0, 10);
                const [year, month, day] = theDate.split('-')
                return `${day}/${month}/${year}`;
            },
            parseDate(date) {
                if (!date) return null;
                let theDate = date.toString().substr(0, 10);
                const [month, day, year] = theDate.split('/');
                return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`;
            },
            computeTagImage(value) {
                let str;
                switch (value) {
                    case 'project':
                        str = 'Proyecto';
                        break;
                    case 'architectonic':
                        str = 'Arquitectura';
                        break;
                    case 'disposition':
                        str = 'Disposición';
                        break;
                }
                return str;
            },
            queryReady(val) {
                return val !== null && val !== undefined && val !== "";
            },
            onClear() {
                this.$emit("input", null);
                this.$emit("clear");
            },
            async searchPlaces(query) {
                // console.log('val:', val);
                if (query === null || query.length === 0) return;
                // Items have already been loaded
                let url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?types=address&access_token=${this.mapBoxApiKey}`;
                this.loadingPlaces = true;
                // Lazily load input items
                fetch(url)
                    .then(res => res.json())
                    .then(res => {
                        // console.log('res: ', res);
                        const {query, features} = res;
                        this.query = query;
                        this.placesList = features;
                        // console.log('places: ', this.places);
                    })
                    .catch(err => {
                        console.log('Error ejecutando API: ', err);
                    })
                    .finally(() => (this.loadingPlaces = false));
            },
            getSelectedItem(item) {
                // console.log('Item selected: ', item);
                this.selectedPlace = Object.assign({}, item);
            },
            getProjects() {
                this.loadingData = true;
                // Catalogo de Proyectos...
                const archived = Number(this.archived);
                projectsService.getViewList(archived).then(record => {
                    this.projects = record.value;
                    // console.log("Proyectos: ", this.projects);
                    this.loadingData = false;
                });
            },
            showArchivedProjects() {
                this.archived = !this.archived;
                this.projects = [];
                this.getProjects();
            },
            newItem() {
                this.valid = false;
                this.editedItem = Object.assign({}, this.defaultItem);
            },
            editItem(item) {
                this.editedIndex = this.projects.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialog = true;
            },
            deleteItem(item) {
                this.editedIndex = this.projects.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialogDelete = true;
            },
            deleteItemConfirm() {
                this.closeDelete();
                this.archiveItemId = this.editedItem;
                // console.log("this.editedItem.id: ", this.editedItem.id);
                projectsService.deleteRecord(this.editedItem.id).then(
                    response => {
                        // console.log("response: ", response);
                        if (response.success) {
                            this.projects.splice(this.editedIndex, 1);
                            this.snackText = 'Proyecto eliminado con éxito.';
                            this.snackBar = true;
                            this.getProjects();
                        } else {
                            this.snackText = 'Error ejecutando API';
                        }
                    }).catch(err => {
                    console.log('Error ejecutando API: ', err);
                    this.messageError = "Un error Impidió eliminar este Proyecto. " +
                        "Esto ocurre debido a que el proyecto esta relacionado con un Inversionista " +
                        "o el Proyecto contiene Avances asociados. <br/><br/> "+
                        "¿Desea ARCHIVAR el Proyecto?";
                    // console.log("this.archiveItemId: ", this.archiveItemId);
                    this.archiveItem(this.archiveItemId);
                    this.dialogDeleteError = true;
                }).finally(() => {
                    // console.log("result: ", result);
                    // this.closeDelete();
                    // this.snackBar = true;
                });
            },
            archiveItem(item) {
                this.editedIndex = this.projects.indexOf(item);
                this.editedItem = Object.assign({}, item);
                // this.dialogArchive = true;
            },
            archiveItemConfirm() {
                this.closeArchive();
                this.closeDialogDeleteError();
                // console.log("this.editedItem.id: ", this.editedItem.id);
                const archived = Number(true);
                projectsService.archiveRecord(this.editedItem.id, archived).then(
                    response => {
                        console.log("response: ", response);
                        if (response.success) {
                            this.projects.splice(this.editedIndex, 1);
                            this.snackText = 'Proyecto archivado con éxito.';
                            this.snackBar = true;
                            this.getProjects();
                        } else {
                            this.snackText = 'Error ejecutando API';
                        }
                    }).catch(err => {
                    console.log('Error ejecutando API: ', err);
                    this.messageError = "Un error impidió archivar este Proyecto";
                    this.dialogError = true;
                });
            },
            undoArchiveItem(item) {
                this.editedIndex = this.projects.indexOf(item);
                this.editedItem = Object.assign({}, item);
                const archived = Number(false);
                projectsService.archiveRecord(this.editedItem.id, archived).then(
                    response => {
                        console.log("response: ", response);
                        if (response.success) {
                            this.projects.splice(this.editedIndex, 1);
                            this.snackText = 'Proyecto desarchivado con éxito.';
                            this.snackBar = true;
                            this.getProjects();
                        } else {
                            this.snackText = 'Error ejecutando API';
                        }
                    }).catch(err => {
                    console.log('Error ejecutando API: ', err);
                    this.messageError = "Un error impidió desarchivar este Proyecto";
                    this.dialogError = true;
                });
            },
            close() {
                // this.$refs.form.reset();
                this.dialog = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },
            closeDelete() {
                this.dialogDelete = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },
            closeArchive() {
                this.dialogArchive = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },
            save() {
                this.$refs.form.validate();
                if (this.$refs.form.validate(true)) {
                    // console.log('selectedPlace: ', this.selectedPlace);
                    if (this.selectedPlace.center && this.selectedPlace.center.length > 0) {
                        this.editedItem.location = this.selectedPlace.place_name;
                        this.editedItem.map_lng = this.selectedPlace.center[0];
                        this.editedItem.map_lat = this.selectedPlace.center[1];
                    }
                    // console.log('editedItem: ', this.editedItem);
                    if (this.editedIndex > -1) {
                        // Actualizar el registro por el metodo PUT
                        Object.assign(this.projects[this.editedIndex], this.editedItem);
                        projectsService.updateRecord(this.editedItem).then(
                            response => {
                                if (response.success) {
                                    this.getProjects();
                                }
                            }
                        );
                    } else {
                        // Agrega el registro por el metodo POST
                        this.projects.push(this.editedItem);
                        projectsService.addRecord(this.editedItem).then(
                            response => {
                                if (response.success) {
                                    this.getProjects();
                                }
                            }
                        )
                    }
                    this.close();
                    this.snackText = 'Registro guardado con éxito.';
                    this.snackBar = true;
                }
            },
            /* Para administrar los documentos */
            loadListDocuments(item) {
                this.selectedPDFFiles = [];
                this.pdfFiles = [];
                // Carga los archivos PDF...
                projectsService.getRecord(item.id).then(fetch_data => {
                    this.editedItem = Object.assign({}, fetch_data.value);
                });
                if (!this.dialogDocumentsPDF) this.dialogDocumentsPDF = true;
            },
            removeChipPDF(index) {
                this.pdfFiles.splice(index, 1);
                this.selectedPDFFiles = [
                    ...this.pdfFiles,
                ];
            },
            inputPDFChanged() {
                this.pdfFiles = [
                    ...this.selectedPDFFiles,
                ];
            },
            uploadDocuments() {
                // console.log('id del record: ', this.editedItem.id);
                let errorCount = 0;
                for (let i = 0; i < this.pdfFiles.length; i++) {
                    let reader = new FileReader();
                    reader.onload = () => {
                        // this.pdfPreview = reader.result;
                        // console.log('reader.result: ', reader.result);
                    };
                    // console.log('this.pdfFiles[]: ', this.pdfFiles[i]);
                    reader.readAsDataURL(this.pdfFiles[i]);
                    projectsService.uploadDocument(this.editedItem.id, -1, this.pdfFiles[i]).then(result => {
                        if (result.success) {
                            this.loadListDocuments(this.editedItem);
                        } else {
                            errorCount++;
                        }
                    });
                }
                this.snackText = (errorCount > 0) ?
                    'Un error impidió subir al menos un documento al servidor' :
                    'Documento subido al servidor. Lista actualizada';
                this.snackBar = true;
            },
            deleteDocument(item) {
                // Elimina la imagen seleccionada desde la bd y del servidor...
                this.currentDocument = Object.assign({}, item);
                this.dialogDeleteDocument = true;
            },
            deleteItemConfirmDocument() {
                // console.log('Current document: ', this.currentDocument);
                projectsService.deleteDocument(this.currentDocument.project_id, this.currentDocument.id).then(result => {
                    this.closeDeletePicture();
                    if (result.success) {
                        this.snackText = 'Documento eliminado desde la lista';
                        this.loadListDocuments(this.editedItem);
                    } else {
                        this.snackText = 'Un error impidió eliminar el documento desde la lista';
                    }
                    this.snackBar = true;
                    this.dialogDeleteDocument = false;
                });
            },
            closeDeleteDocument() {
                this.dialogDeleteDocument = false;
            },
            /* Para administrar las imágenes */
            loadGalleryImages(item) {
                this.selectedFiles = [];
                this.imageFiles = [];
                // Carga las imágenes...
                projectsService.getRecord(item.id).then(fetch_data => {
                    this.editedItem = Object.assign({}, fetch_data.value);
                });
                if (!this.dialogGalleryPhotos) this.dialogGalleryPhotos = true;
            },
            removeImageChip(index) {
                this.imageFiles.splice(index, 1);
                this.selectedFiles = [
                    ...this.imageFiles,
                ];
            },
            inputImageChanged() {
                this.imageFiles = [
                    ...this.selectedFiles,
                ];
            },
            uploadPictures() {
                // console.log('id del record: ', this.editedItem.id);
                let errorCount = 0;
                for (let i = 0; i < this.imageFiles.length; i++) {
                    let reader = new FileReader();
                    reader.onload = () => {
                        this.imagePreview = reader.result;
                    };
                    reader.readAsDataURL(this.imageFiles[i]);
                    projectsService.uploadImage(this.editedItem.id, -1, this.imageFiles[i]).then(result => {
                        if (result.success) {
                            this.loadGalleryImages(this.editedItem);
                        } else {
                            errorCount++;
                        }
                    });
                }
                this.snackText = (errorCount > 0) ?
                    'Un error impidió subir al menos una imagen al servidor!' :
                    'Imagen subida al servidor. Galería actualizada';
                this.snackBar = true;
            },
            deletePicture(item) {
                // Elimina la imagen seleccionada desde la bd y del servidor...
                this.currentPicture = Object.assign({}, item);
                this.dialogDeletePicture = true;
            },
            deleteItemConfirmPicture() {
                projectsService.deleteImage(this.currentPicture.pivot.project_id, this.currentPicture.id).then(result => {
                    this.closeDeletePicture();
                    if (result.success) {
                        this.snackText = 'Imagen eliminada desde la galería!';
                        this.loadGalleryImages(this.editedItem);
                    } else {
                        this.snackText = 'Un error impidió eliminar la imagen desde la galería';
                    }
                    this.snackBar = true;
                });
            },
            closeDeletePicture() {
                this.dialogDeletePicture = false;
            },
            tagImage(picture_id, tag_value) {
                projectsService.tagImage(this.editedItem.id, picture_id, tag_value)
                    .then(result => {
                        if (result.success) {
                            this.snackText = 'Imagen ha sido etiquetada dentro de la galería';
                            this.loadGalleryImages(this.editedItem);
                        } else {
                            this.snackText = 'Un error impidió etiquetar la imagen seleccionada';
                        }
                        this.snackBar = true;
                    });
            },
            markAsFavorite(item) {
                // console.log('Item Image:', item);
                projectsService.markAsFavorite(item.pivot.project_id, item.id)
                    .then(result => {
                        if (result.success) {
                            this.snackText = 'La Imagen ha sido marcada como favorita dentro de la galería';
                            // this.loadGalleryImages(item.pivot.proyect_id);
                            this.selectedFiles = [];
                            this.imageFiles = [];
                            // Carga las imagenes...
                            projectsService.getRecord(item.pivot.project_id).then(fetch_data => {
                                this.editedItem = Object.assign({}, fetch_data.value);
                            });
                        } else {
                            this.snackText = 'Un error impidió marcar la imagen seleccionada como favorita';
                        }
                        this.snackBar = true;
                    });
            },
            closeDialogError() {
                this.dialogError = false;
                this.messageError = '';
            },
            closeDialogDeleteError() {
                this.closeDelete();
                this.dialogDeleteError = false;
            }
        },
        mounted() {
            this.archived = false;
            this.projects = [];
            this.getProjects();
        }
    }
</script>

<style scoped>
    .v-btn {
        text-transform: none !important;
    }
</style>